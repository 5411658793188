export const MetaTags = () => {
  return (
    <>
      <meta property="og:url" content="www.brokenplanet.com" />
      <meta
        name="viewport"
        content="width=device-width, viewport-fit=cover, initial-scale=1"
      />
      <title>Broken Planet</title>
      <meta name="title" content="Broken Planet" />
      <meta
        name="description"
        content="Shop Broken Planet. Broken Planet is based in London, UK and offers a range of clothing, including hoodies, sweatpants, sweaters, t-shirts, jeans and more, as well as different accessories such as sunglasses and jewellery. All products are made of organic and recycled materials."
      />
      <meta property="og:image" content="/share-image.png" />
      <meta property="og:image:alt" content="Brokenplanet logo" />
      <meta property="og:title" content="Broken Planet" />
      <meta
        property="og:description"
        content="Shop Broken Planet. Broken Planet is based in London, UK and offers a range of clothing, including hoodies, sweatpants, sweaters, t-shirts, jeans and more, as well as different accessories such as sunglasses and jewellery. All products are made of organic and recycled materials."
      />
      <meta name="theme-color" content="#ffffff" />
      <meta name="format-detection" content="telephone=no" />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="/favicon-dark-32x32.png"
        media="(prefers-color-scheme: dark)"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href="/favicon-dark-16x16.png"
        media="(prefers-color-scheme: dark)"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="/favicon-light-32x32.png"
        media="(prefers-color-scheme: light)"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href="/favicon-light-16x16.png"
        media="(prefers-color-scheme: light)"
      />
      <link rel="shortcut icon" href="/share-image.png" />
    </>
  );
};
